import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/worker/user/login.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: login,
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	{
		path: '/setPass',
		name: 'setPass',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/user/setPass.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			// requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},
	//我的资料
	{
		path: '/myInfo',
		name: 'myInfo',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/user/myInfo.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			// requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},
	
	//关于我们
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/user/about.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			// requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},
	
	{
		path: '/pay',
		name: 'pay',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/pay.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	{
		path: '/pay2',
		name: 'pay2',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/pay2.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},

	//订单详情
	{
		path: '/orderDetails',
		name: 'orderDetails',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/orderDetails.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//电子票据
	{
		path: '/electronicBill',
		name: 'electronicBill',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/electronicBill.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//缴费选择页
	{
		path: '/auth_info',
		name: 'auth_info',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/auth_info.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//缴费入口
	{
		path: '/indexCenter',
		name: 'indexCenter',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/indexCenter.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//缴费中心
	{
		path: '/payIn',
		name: 'payIn',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/payIn.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//判断是否微信端
	{
		path: '/scan_index',
		name: 'scan_index',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/scan_index.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},
	
	//订单详情
	{
		path: '/orderList',
		name: 'orderList',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/parent/pay/orderList.vue'),
		meta: {
			title: '新华书店教辅收费系统'
		}
	},

	{
		path: '/personalCenter',
		name: 'personalCenter',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/my/personalCenter.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},

	{
		path: '/systemSet',
		name: 'systemSet',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/user/systemSet.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},

	//修改密码
	{
		path: '/modifyPassword',
		name: 'modifyPassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/user/modifyPassword.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},

	{
		path: '/payOrder',
		name: 'payOrder',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/my/payOrder.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},

	{
		path: '/payOrderDetails',
		name: 'payOrderDetails',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/worker/my/payOrderDetails.vue'),
		meta: {
			title: '新华书店教辅收费系统',
			requireAuth: true // 配置此条，进入页面前判断是否需要登陆
		}
	},

]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	// base: '/schoolPay/',
	base: '',
	routes
})

export default router
