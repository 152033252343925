<template>
	<div class="login_body">
		<div class="login_n_box">

			<div class="login_title">
				<img class="logoText" src="../../../assets/logoText.png" width="30%" />
				教辅收费系统
			</div>

			<el-form class="form_box" :model="ruleForm" status-icon :rules="rules" ref="ruleForm">

				<el-form-item prop="user">
					<el-input class="login_input" placeholder="输入账号" v-model="ruleForm.user"></el-input>
				</el-form-item>

				<el-form-item prop="pass">
					<el-input class="login_input" placeholder="输入密码" v-model="ruleForm.pass" show-password></el-input>
				</el-form-item>

				<el-form-item>
					<el-button class="login_sub" type="danger" @click="submitForm('ruleForm')">确定</el-button>
				</el-form-item>
<!-- 				<router-link to="/setPass">
					<el-form-item class="wj_pass">
						忘记密码？
					</el-form-item>
				</router-link> -->
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	export default {
		data() {
			return {
				ruleForm: {
					pass: "",
					user: "",
				},
				openId: "",
				rules: {
					user: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}, ],
					pass: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, ],
				}
			};
		},
		created() {
			//获取openId
			this.openId = this.$route.query.openId;
			
			this.isLogin();
		},
		methods: {

			isLogin() {
				if (localStorage.getItem('userInfo')) { // 通过vuex state获取当前的token是否存在
					this.$router.push('/personalCenter')
				}
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						let params = {
							loginName: this.ruleForm.user,
							loginPassword: this.ruleForm.pass,
							clientNo: this.openId,
						};

						this.axios
							.post(url + '/login', params, {})
							.then(res => {
								let data = res.data;
								if (data.code == 200) {
									localStorage.setItem('userInfo', JSON.stringify(data.data));
									// this.$notify({
									// 	title: '提示',
									// 	message: data.msg,
									// 	type: 'success'
									// });
									this.$router.push('/personalCenter')
								} else {
									this.$notify.error({
										title: '提示',
										message: data.msg,
										type: 'success'
									});
								}

							})
							.catch(error => console.log(error))
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
	}
</script>

<style scoped="scoped">
	.login_body {
		background: url("../../../assets/login_bj.jpg") no-repeat;
		background-size: 100% 100%;
		height: 100vh;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 7px;
		margin-top: 34%;
	}

	.login_title {
		font-size: .4rem;
		margin: 55px 0 40px 0;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 30px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: .4rem;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		font-size: .35rem;
		color: #333;
	}

	.login_text_g {
		color: #333;
	}

	.login_input {
		border: none;
		border-bottom: 1px solid #dddddd;
	}

	.login_input>>>input {
		border: none;
	}

	.form_box {
		float: left;
		margin-left: 4%;
		width: 92%;
	}

	.login_sub {
		width: 90%;
		border-radius: 20px;
		background: #ab1e31;
	}

	.login_sub>>>span {
		font-size: .35rem;
	}

	.wj_pass {
		color: #285d16;
	}

	.logoText {
		margin-right: 5px;
		vertical-align: middle;
		margin-top: -3px;
	}
</style>
