import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'

import axios from 'axios';
Vue.prototype.axios = axios;

Vue.use(ElementUI);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	to.meta.title && (document.title = to.meta.title);
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem('userInfo')) { // 通过vuex state获取当前的token是否存在
			next();
		} else {
			location.href="https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net/";
			return;
		}
	} else {
		next();
	}
});


new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
